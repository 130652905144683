<template>
  <div
    v-if="lesson"
    class="page-lesson"
    :data-lesson-id="lesson.id"
  >
    <div
      class="page-header"
      :data-lesson-id="lesson.id"
    >
      <router-link :to="{ name: 'pmp-lesson', params: { id: lesson.id }}" class="page-header__link-back">
        <i class="material-icons">keyboard_arrow_left</i>
      </router-link>
      <div class="page-header__info">
        <span class="page-header__breadcrumbs">
          Lesson {{ lesson.id }}
          <i class="material-icons">arrow_right</i>
          Objective {{ formatObjectiveId(objective.id) }}
        </span>
        <span class="page-header__title">{{ objective.name }}</span>
      </div>
    </div>

    <div class="table-container">
      <table class="table-objective-details">
        <thead>
          <tr>
            <th>Enablers</th>
            <th>Eco</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="enabler in objective.enablers" :key="enabler.id">
            <td>{{ enabler.name }}</td>
            <td>{{ enabler.eco }}</td>
          </tr>
        </tbody>
      </table>

      <table class="table-objective-details">
        <thead>
          <tr>
            <th>Deliverables</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="deliverable in objective.deliverables" :key="deliverable.id">
            <td>{{ deliverable.name }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <table class="table-objective-details">
        <thead>
          <tr>
            <th>Tools</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="tool in objective.tools" :key="tool.id">
            <td>{{ tool.name }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <table class="table-objective-details activities">
        <thead>
          <tr>
            <th>Activities</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="activity in objective.activities" :key="activity.id">
            <td>{{ activity.name }}</td>
            <td>{{ activity.time }} m</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

</template>

<script>
export default {
  name: 'PmpObjective',

  props: ['id'],

  computed: {
    lesson () {
      return this.$store.state.pmpData.lessons.find((lesson) => {
        return lesson.id === this.objective.lesson
      })
    },

    objective () {
      return this.$store.state.pmpData.objectives.find((objective) => {
        return objective.id === this.id
      })
    }
  },

  methods: {
    formatObjectiveId (id) {
      return id.split('.')[1]
    }
  }

}
</script>

<style lang="scss" scoped>
  @import '@/styles/_variables.scss';

  .page-lesson {
    height: 100%;
    display: flex;
    flex-direction: column;

    &[data-lesson-id="1"] {
      background: $orange-light;

      .table-objective-details th {
        border-bottom: 1px solid $orange-mid;
      }
    }

    &[data-lesson-id="2"] {
      background: $jtask-blue-light;

      .table-objective-details th {
        border-bottom: 1px solid $jtask-blue-mid;
      }
    }

    &[data-lesson-id="3"] {
      background: $blue-light;

      .table-objective-details th {
        border-bottom: 1px solid $blue-mid;
      }
    }

    &[data-lesson-id="4"]{
      background: $green-light;

      .table-objective-details th {
        border-bottom: 1px solid $green-mid;
      }
    }

    &[data-lesson-id="5"] {
      background: $red-light;

      .table-objective-details th {
        border-bottom: 1px solid $red-mid;
      }
    }
  }

  .page-header {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
  }

  .page-header__link-back {
    flex: 0 0 45px;
    color: #fff;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .page-header__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFFFFF;
    padding: 15px 15px 15px 0;
  }

  .page-header__breadcrumbs {
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 3px;
    margin-top: -8px;

    i {
      position: relative;
      bottom: -6px;
    }
  }

  .page-header__title {
    font-size: 15px;
    line-height: 1.4;
  }

  .table-container {
    overflow-y: auto;
    padding: 5px;
  }

  .table-objective-details {
    width: 100%;
    padding: 20px 10px 10px 10px;
    border-collapse: collapse;

    th {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      text-align: left;
      padding: 30px 20px 5px 20px;

      &:nth-child(1) {
        width: 90%;
      }
    }

    td {
      font-size: 14px;
      line-height: 1.4;
      vertical-align: top;
      padding: 3px 20px;

      &:nth-child(1) {
        padding-right: 0;
      }

    }

    tr:nth-child(1) td {
      padding-top: 10px;
    }

    &.activities {
      width:100%;

      th:nth-child(1) {
        // width: 80%;
      }
    }

  }

</style>
